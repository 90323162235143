/* testimonials_sec */
.testimonials_sec {
    background-color: #11589a;
    color: #fff;
  
  }
  
  .testimonials_sec .testimonials_content .contented {
    border: 3px solid rgba(0, 0, 0, 0.26);
    padding: 30px;
    position: relative;
    z-index: 1;
    background-color: #00000054;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  
  .testimonials_sec .testimonials_content .contented .coma_sty {
    margin-bottom: 30px;
    position: relative;
    margin-top: -66px;
    width: 67px;
  }
  
  .testimonials_sec .testimonials_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
  }
  
  .testimonials_sec .testimonials_content .top_content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .testimonials_sec .testimonials_content .content_profile {
    position: relative;
    z-index: 1;
    margin-top: -95px;
  
  }
  
  .review_slider .item {
    margin: 70px 0 0;
  
  }
  
  .testimonials_sec .testimonials_content .content_profile img {
    min-width: 60px;
    min-height: 60px;
    border-radius: 50px;
    overflow: hidden;
  }
  
  
  .owl-nav .owl-prev span {
    position: absolute;
    left: -17px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .owl-nav .owl-next span {
    position: absolute;
    right: -17px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  button.owl-prev span {
    width: 30px;
    height: 30px;
    background: #000;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 23px;
    justify-content: center;
    border-radius: 50px;
  }
  
  button.owl-next span {
    width: 30px;
    height: 30px;
    background: #000;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 23px;
    justify-content: center;
    border-radius: 50px;
  }