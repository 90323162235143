@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
  font-size: 16px;
  color: #000;
  background-color: #fff;
  scroll-behavior: smooth;
  overflow-x: hidden !important;
  font-family: 'Poppins', sans-serif;
}

.body{
overflow-x: hidden;
}
a {
  color: #000;
  text-decoration: none;
  transition: all 0.5s;
}

a:hover {
  text-decoration: none;
  transition: all .6s;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {

}

p {
  margin: 0;
  line-height: 30px;
  margin-bottom: 25px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
}

ul li{
  margin-bottom: 0px;
 
}

.img-fluid {
  display: block;
  width: 100%;
  max-width: 100%;
}

.li {
  display: inline-block;
  padding: 0px 5px;
}
ul{
list-style: none;
}

h1 {
font-size: 55px;

}

h2 {
font-size: 45px;
margin-bottom: 15px;
}
h3 {
font-size: 35px;
margin-bottom: 15px;
}

h4 {
font-size: 25px;
margin-bottom: 15px;
font-weight: 300;
}

h5 {

}

h6 {}



.bg-cover {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.row-below {
  margin-top: 3%;
}
.row_gap{
  margin-bottom: 15px;
}

section {
  padding: 70px 0px;
}

.form-control:focus {
  border-color: #ced4da;
  outline: 0;
  box-shadow: none;
}

.m-backtotop {
-webkit-transition: all 0.3s ease-in-out;
-moz-transition: all 0.3s ease-in-out;
-ms-transition: all 0.3s ease-in-out;
-o-transition: all 0.3s ease-in-out;
transition: all 0.3s ease-in-out;
position: fixed;
bottom: 15px;
right: 20px;
width: 50px;
height: 50px;
background: rgb(10 63 111);
border-radius: 25px;
text-align: center;
border: 2px solid rgb(10 63 111);
box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
opacity: 0;
overflow: hidden;
color: #fff;
}

.m-backtotop.active {
bottom: 60px;
opacity: 1;
}

.m-backtotop>div {
-webkit-transition: all 0.3s ease-in-out;
-moz-transition: all 0.3s ease-in-out;
-ms-transition: all 0.3s ease-in-out;
-o-transition: all 0.3s ease-in-out;
transition: all 0.3s ease-in-out;
}

.m-backtotop>div.arrow {
position: absolute;
top: 50%;
left: 50%;
transform: translateY(-50%) translateX(-50%);
opacity: 1;
font-size: 40px;
}

.m-backtotop>div.text {
font-size: 5px;
font-size: 0.5rem;
line-height: 10px;
text-transform: uppercase;
font-weight: 900;
position: absolute;
left: 50%;
/*   top: 50%; */
transform: translateY(50%) translateX(-50%);
opacity: 0;
margin-top: 25px;
}

.m-backtotop:hover {
transform: scale(1.1);
cursor: pointer;
background: black;
box-shadow: 0 10px 5px rgba(0, 0, 0, 0.1);
}

.m-backtotop:hover>div.arrow {
transform: translateY(-150%) translateX(-50%);
opacity: 0;
}

.m-backtotop:hover>div.text {
transform: translateY(-50%) translateX(-50%);
opacity: 1;
}
/* ..........Button.......... */
.btn1 {

  background: #11589a;
  border: 1px solid #11589a;
  padding: 11px 0px;
  display: inline-block;
  color: #ffffff;
  width: 250px;
  font-weight: 400;
  text-align: center;
  text-transform: capitalize;
  transition: all 0.5s ease-in-out;
  border-radius: 50px;
}
.btn1:hover{
  background: initial;
  border: 1px solid #11589a;
  color: #11589a;
}

.btn2 {

  background: #000000;
  border: 1px solid #000000;
  padding: 11px 0px;
  display: inline-block;
  color: #ffffff;
  width: 250px;
  font-weight: 400;
  text-align: center;
  text-transform: capitalize;
  transition: all 0.5s ease-in-out;
  border-radius: 50px;
}
.btn2:hover{
  background: #000;
  border: 1px solid #000000;
  color: #ffffff;
}

.btn3 {
  background: initial;
  border: 1px solid #11589a;
  padding: 11px 0px;
  display: inline-block;
  color: #11589a;
  width: 250px;
  font-weight: 400;
  text-align: center;
  text-transform: capitalize;
  transition: all 0.5s ease-in-out;
  border-radius: 50px;
}
.btn3:hover{
  background: #11589a;
  color: #fff;
  border: 1px solid #11589a;
}
.btn_gap{
  margin-right: 10px
}
/* --TOP BAR-- */

.top_ber {
  background-color: #11589a;
  color: #fff;
  padding: 5px 0;
}
.top_ber .container-flex{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top_ber .topber_left ul{
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 20px;
}
.top_ber .topber_left ul li a{
  color: #fff;
}
.top_ber .topber_left ul li a img {
	width: 35px;
}
.top_ber .tober_right a {
	color: #fff;
	display: flex;
	align-items: center;
  gap: 5px;
}
.top_ber .tober_right a i{
  font-size: 20px;
}


/*Header Bottom Area*/
.header-bottom {
    background: #fff;
    position: relative;
   
}

.sticky-header.sticky {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background: #333;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  animation-name: fadeInDown;
  animation-duration: 900ms;
  animation-timing-function: cubic-bezier(0.2, 1, 0.22, 1);
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  border-bottom: 0;
}

.main-menu {
  position: relative;
}
.main-menu nav > ul > li {
  position: relative;
  display: inline-block;
}
.main-menu nav > ul > .has-dropdown {
  margin-right: 5px;
}
.main-menu nav > ul > .has-dropdown:last-child {
  margin-right: 0;
}
.main-menu nav > ul > .has-megaitem {
  position: static;
}
.main-menu nav > ul > li > a {
  display: block;
  line-height: 24px;
  padding: 15px 12px;
  font-size: 16px;
  line-height: 30px;
  text-transform: capitalize;
  font-weight: 500;
  color: #2a2a2a;
}
/* .main-menu nav > ul > li > a:hover, .main-menu nav > ul > li > a.active {
  background: #11589a;
  color: #FFF;
} */

.sub-menu > li > a,
.mega-menu-sub > li > a {
  font-weight: 400;
  display: block;
  line-height: 35px;
  text-transform: capitalize;
  color: #666;
  position: relative;
  transition: all 0.3s ease 0s;
}
.sub-menu > li > a::after,
.mega-menu-sub > li > a::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  transform: translate(0px, -50%);
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: #11589a;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease 0s;
}
.sub-menu > li > a:hover,
.mega-menu-sub > li > a:hover {
  color: #11589a;
  transform: translateX(10px);
}
.sub-menu > li > a:hover::after,
.mega-menu-sub > li > a:hover::after {
  transform: translate(-10px, -50%);
  visibility: visible;
  opacity: 1;
}

.sub-menu {
	position: absolute;
	min-width: 266px;
	padding: 15px 15px;
	background: #FFF;
	box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.1);
	transform: perspective(600px) rotateX(-20deg);
	transform-origin: 0 0 0;
	left: 0;
	right: auto;
	opacity: 0;
	visibility: hidden;
	transition: 0.5s;
	z-index: 99;
	top: 100%;
	text-align: left;
}

.mega-menu {
  position: absolute;
  min-width: 100%;
  padding: 25px;
  background: #FFF;
  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.1);
  transform: perspective(600px) rotateX(-20deg);
  transform-origin: 0 0 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  z-index: 99;
  top: 100%;
  text-align: left;
}

.mega-menu-inner {
  display: flex;
  justify-content: space-between;
}

.mega-menu-item-title {
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  margin-bottom: 13px;
  color: #333;
}
.mega-menu-item-title:hover {
  color: #11589a;
}
.mobile-logo-link img {
	width: 135px !important;
  cursor: pointer !important;
}
.mobile-menu-top img {
	width: 125px;
}
.mega-menu-sub > li {
  display: block;
}

.main-menu nav > ul > li:hover .sub-menu,
.main-menu nav > ul > li:hover .mega-menu {
  opacity: 1;
  visibility: visible;
  transform: perspective(600px) rotateX(0deg);
}

.menu-banner {
  margin-top: 20px;
}
.menu-banner > a {
  display: block;
  width: 100%;
}
.menu-banner img {
  width: 100%;
}

.mobile-header-wrapper {
padding: 15px 0;
}

.mobile-menu {
  border: 1px solid #000;
  padding: 5px;
  display: block;
  border-radius: 4px;
}

.mobile-menu-dash {
  display: block;
  width: 30px;
  height: 2px;
  background: #000;
  margin-top: 5px;
  margin-bottom: 5px;
}

/*****************************
Layout - Offcanvas
*****************************/
.offcanvas {
  z-index: 999999;
  position: fixed;
  top: 0;
  overflow: auto;
  height: 100vh;
  padding: 16px;
  transition: all 0.3s ease 0s;
  transform: translateX(100%);
  box-shadow: none;
  background: #212121;
  color: #eaeaea;
  width: 315px;
  overflow-x: hidden;
  box-shadow: 0 3.4px 2.7px rgb(0 0 0 / 1%), 0 8.7px 6.9px rgb(0 0 0 / 1%), 0 17.7px 14.2px rgb(0 0 0 / 1%), 0 36.5px 29.2px rgb(0 0 0 / 1%), 0 100px 80px rgb(0 0 0 / 2%);
}
.offcanvas-menu > ul > li {
  border-bottom: 1px solid #2f2f2f !important;
}
.offcanvas-menu > ul > li > a{
  display: block;
}
.offcanvas-leftside {
  left: 0%;
  transform: translateX(-100%);
}
ul.mobile-sub-menu > li > a {
  font-size: 14px;
  font-weight: 300;
}

.offcanvas-rightside {
  right: 0%;
  transform: translateX(100%);
}

.offcanvas.offcanvas-open {
  transform: translateX(0);
}

.offcanvas-overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.offcanvas-header {
  margin-bottom: 30px;
}

.offcanvas-close {
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  background:#fff;
  border-radius: 50%;
  transition: all 0.3s ease 0s;
}
.offcanvas-close:hover {
  color: #11589a;
  border-color: #11589a;
}

.offcanvas-title {
  margin-bottom: 40px;
}

/*mobile-menu Top Area*/
.mobile-menu-top {
  font-size: 13px;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.mobile-menu-user-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  justify-content: center;
}
.mobile-menu-user-menu > li {
  margin-right: 40px;
  position: relative;
}
.mobile-menu-user-menu > li:last-child {
  margin-right: 0;
}
.mobile-menu-user-menu > li::after {
  position: absolute;
  content: "|";
  top: 50%;
  transform: translateY(-50%);
  right: calc(0% - 20px);
}
.mobile-menu-user-menu > li:last-child::after {
  display: none;
}
.mobile-menu-user-menu > li > a {
  display: inline-block;
  line-height: 46px;
  color: #666 !important;
}
.mobile-menu-user-menu > li > a:hover {
  color: #11589a;
}

.has-mobile-user-dropdown {
  position: relative;
  padding-right: 15px;
}
.has-mobile-user-dropdown::before {
  content: "";
  font-family: "FontAwesome";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-weight: 400;
}

.mobile-user-sub-menu {
  position: absolute;
  top: calc(100%);
  left: 50%;
  transform: translate(-50%, -30px);
  padding: 10px 15px;
  width: 160px;
  background: #FFF;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  transition: all 0.3s ease 0s;
  visibility: hidden;
  opacity: 0;
  z-index: 99;
}
.mobile-user-sub-menu > li {
  display: block;
  border-bottom: 1px solid #eee;
  padding: 5px 0;
}
.mobile-user-sub-menu > li:last-child {
  border-bottom: none;
}
.mobile-user-sub-menu > li > a {
  position: relative;
  color: #666;
  display: flex;
  align-items: center;
}
.mobile-user-sub-menu > li > a:hover {
  color: #11589a;
}

.user-sub-menu-link-icon {
  padding-right: 5px;
}

.has-mobile-user-dropdown:hover .mobile-user-sub-menu {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, 0);
}

/*Mobile Menu Center Area*/
.mobile-menu-customer-support {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Raleway", sans-serif;
}

.mobile-menu-customer-support-icon {
  max-width: 50px;
  overflow: hidden;
  margin-right: 30px;
}

.mobile-menu-customer-support-icon img {
  width: 100%;
}

.mobile-menu-customer-support-text-phone {
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: #000;
  transition: all 0.3s ease 0s;
}
.mobile-menu-customer-support-text-phone:hover {
  color: #11589a;
}

.mobile-menu-customer-support-text {
  font-size: 14px;
  margin: 20px 0;
}

.mobile-action-icon {
  display: flex;
  justify-content: center;
}

.mobile-action-icon-item {
  margin-right: 40px;
}
.mobile-action-icon-item:last-child {
  margin-right: 0;
}

.mobile-action-icon-link {
  position: relative;
  font-size: 27px;
  color: #333;
  transition: all 0.3s ease 0s;
}
.mobile-action-icon-link:hover {
  color: #11589a;
}

.mobile-action-icon-item-count {
  position: absolute;
  top: 0;
  right: calc(0% - 10px);
  font-size: 11px;
  display: inline-block;
  background: #333;
  color: #FFF;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transition: all 0.3s ease 0s;
}

.mobile-action-icon-link:hover .mobile-action-icon-item-count {
  background: #11589a;
}

/*Mobile Menu Bottom Area*/
.mobile-menu-bottom {
    position: fixed;
    bottom: 5%;
}
.offcanvas-menu {
  margin: 20px 0;
}

.offcanvas-menu li {
  position: relative;
  padding: 5px 5px;
}

.offcanvas-menu > ul > li {
  border-bottom: 1px solid transparent;
}

.offcanvas-menu > ul > li:last-child {
  border-bottom: none;
}

.offcanvas-menu li a {
  display: block;
  color: #eaeaea;
  text-decoration: none;
}

.offcanvas-menu li a:hover {
  color: #ddd;
}

.offcanvas-menu li .mobile-sub-menu {
  display: none;
}
.offcanvas-menu-expand{
  cursor: pointer;
}
.offcanvas-menu-expand::after {
  content: "";
  position: absolute;
  font-family: "FontAwesome";
  right: 0;
  transition: all 0.3s ease;
  color: #666;
}

.offcanvas-menu .active > .offcanvas-menu-expand:last-child,
.sub-menu > .active .offcanvas-menu-expand:last-child {
  border-bottom: none;
}

.offcanvas-menu .active > .offcanvas-menu-expand::after,
.sub-menu > .active .offcanvas-menu-expand::after {
  color: #777;
  content: "";
}

.mobile-menu-email {
  color: #999;
  text-align: center;
  display: block;
}
.mobile-menu-email:hover {
  color: #11589a;
}

.mobile-menu-social {
  margin-top: 25px;
  text-align: center;
}
.mobile-menu-social > li {
  display: inline-block;
  margin-right: 13px;
}
.mobile-menu-social > li:last-child {
  margin-right: 0px;
}
.mobile-menu-social > li > a {
  display: inline-block;
  font-size: 14px;
  padding: 0;
  line-height: 30px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  text-align: center;
  color: #FFF !important;
}
.mobile-menu-social > li > a:hover {
  opacity: 0.7;
}
.mobile-menu-social > li > a.facebook {
  background: #4867AA;
}
.mobile-menu-social > li > a.twitter {
  background: #1DA1F2;
}
.mobile-menu-social > li > a.youtube {
  background: #FE0000;
}
.mobile-menu-social > li > a.pinterest {
  background: #BD081B;
}
.mobile-menu-social > li > a.instagram {
  background: #B23A94;
}

.offcanvas-cart-item-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.offcanvas-cart-item-single:last-child {
  margin-bottom: 0;
}

.offcanvas-cart-item-block {
  display: flex;
  align-items: center;
}

.offcanvas-cart-item-image-link {
  width: 90px;
  overflow: hidden;
  border: 1px solid #eee;
  margin-right: 20px;
}

.offcanvas-cart-image {
  width: 100%;
}

.offcanvas-cart-item-link {
  display: block;
  font-weight: 700;
}

.offcanvas-cart-item-details {
  font-family: "Raleway", sans-serif;
}

.offcanvas-cart-total-price {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-family: "Raleway", sans-serif;
}

.offcanvas-cart-total-price-text {
  font-weight: 700;
}

.offcanvas-cart-action-button-list {
  margin-bottom: 25px;
  display: block;
}

.offcanvas-cart-action-button-link {
  display: block;
  background: #000;
  color: #FFF;
  padding: 10px 30px;
  text-align: center;
  font-weight: 600;
}
.offcanvas-cart-action-button-link:hover {
  background: #11589a;
  color: #FFF;
}

.offcanvas-wishlist-item-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.offcanvas-wishlist-item-single:last-child {
  margin-bottom: 0;
}

.offcanvas-wishlist-item-block {
  display: flex;
  align-items: center;
}

.offcanvas-wishlist-item-image-link {
  width: 90px;
  overflow: hidden;
  border: 1px solid #eee;
  margin-right: 20px;
}

.offcanvas-wishlist-image {
  width: 100%;
}

.offcanvas-wishlist-item-link {
  display: block;
  font-weight: 700;
}

.offcanvas-wishlist-item-details {
  font-family: "Raleway", sans-serif;
}

.offcanvas-wishlist-total-price-text {
  font-weight: 700;
}

.offcanvas-wishlist-action-button {
  margin-top: 40px;
}

.offcanvas-wishlist-action-button-list {
  margin-bottom: 25px;
  display: block;
}

.offcanvas-wishlist-action-button-link {
  display: block;
  background: #000;
  color: #FFF;
  padding: 10px 30px;
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
}
.offcanvas-wishlist-action-button-link:hover {
  background: #11589a;
  color: #FFF;
}

.header-section .main-menu{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo img {
	width: 150px;
}
.gap_menu{
  margin-top: 20px;
}
.header-section {
	padding: 0px 0;
}

/* banner */
.banner {
	background: url('../../../public/assets/image/banner.jpeg') no-repeat center;
	background-size: cover;
	padding: 120px 0 45px;
	color: #fff;
}
.banner .banner_heading h1 .h1_sub {
	font-weight: 200;
	font-size: 45px;
}
.banner .banner_con {
  text-align: center;
}
.banner .banner_con p {
	font-size: 16px;
	margin-bottom: 0;
}
.banner .banner_con span {
	font-size: 23px;
	display: block;
	font-weight: 500;
	line-height: 31px;
}
.banner_con i {
	font-size: 40px;
	line-height: 50px;
}
.banner .banner_btm {
	margin-top: 40px;
}
.heading h2 {
	font-size: 40px;
	margin-bottom: 15px;
	font-weight: 400;
}

.company_rating>.rating {
  margin-right: 40px
}

.company_rating>.rating span {
  color: #fff;
  font-size: 50px;
  font-weight: 800
}

.company_rating>.rating p {
  color: #fff;
  font-size: 17px
}
/* our_clientele_sec */
.our_clientele_sec .client_box {
  border: 1px solid rgb(201, 201, 201);
  overflow: hidden;
  background-color: #fff;
}
.our_clientele_sec .client_box img{
  filter: grayscale(1.1);
  -webkit-filter: grayscale(1.1);
  transition: 0.5s ease-in;
  cursor: pointer;
}
.our_clientele_sec .client_box:hover img{
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  cursor: pointer;
  transition: 0.5s ease-in;
  transform: scale(1.1);
}
.bg_color{
  background-color: #11589a;
  color: #fff;
}
.row_gap {
	margin-bottom: 25px;
}
/* featured_service_sec */
.featured_service_sec .sevice_card .btom{
padding: 15px 10px;
}
.featured_service_sec .sevice_card {
  background-color: #2a2a2a;

  overflow: hidden;
}
.featured_service_sec .sevice_card p{
  margin-bottom: 0;
}
.featured_service_sec  .service_ul{
  margin-bottom: 0;
}
.featured_service_sec  .service_ul li{
  font-size: 16px;
  margin-bottom: 10px;
}
.featured_service_sec .sevice_card .top_img{
  position: relative;
  overflow: hidden;
}
.featured_service_sec .sevice_card .top_img img{
  transition: all 0.6s;
}
.featured_service_sec .sevice_card:hover .top_img img{
  transform: scale(1.1);
}
.featured_service_sec .sevice_card .top_img .service_ul {
	position: absolute;
	top: -120%;
	left: 0;
	right: 0;
	padding: 25px;
	background-color: #00000073;
	color: #fff;
	width: 100%;
	height: 100%;
  transition: all 0.6s;
}
.featured_service_sec .sevice_card .top_img .service_ul li a{
  color: #fff;
  display: flex;
  align-items: center;
}
.featured_service_sec .sevice_card .top_img .service_ul li{
  transition: all 0.6s;
}
.featured_service_sec .sevice_card .top_img .service_ul li:hover{
  margin-left: 5px;
 
}
/* .featured_service_sec .sevice_card .top_img .service_ul li:hover a{
  letter-spacing: 1px;
} */
.featured_service_sec .sevice_card .top_img .service_ul li a i{
  color: #ffffff;
  background-color: #11589a;
  min-width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.featured_service_sec .sevice_card .top_img:hover .service_ul{
left: 0;
top: 0;
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1220px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 1175px;
  }
  .main-menu nav > ul > li > a {
    display: block;
    line-height: 24px;
    padding: 25px 8px ;
    font-size: 14px;
    line-height: 30px;
    text-transform: capitalize;
    font-weight: 500;
    color: #2a2a2a;
  }
}
.sevice_card .content_left {
  padding: 25px;
}
.sevice_card .content_left ul{
  margin: 15px 0 0;
}
.sevice_card .content_left ul li a{
  color: #fff;
}
.sevice_card .content_img{
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.sevice_card .content_img:hover img{
  transform: scale(1.1);
}
.sevice_card .content_img img{
  width: 100%;
  height: 100%;
  transition: all 0.6s;
}
/* want_work_sec */
.want_work_sec .ul_con{
  margin-bottom: 15px;
}
.want_work_sec .ul_con li{
 display: inline-block ;
 width: 250px;
 margin-bottom: 10px;
}
/* showcasing_content */
.showcasing_content {
  background-color: #11589a;
  color: #fff;
  padding: 30px;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.showcasing_content  .heading {
  padding-right: 35px;
}
.showcasing_slider button.owl-prev{
  position: absolute;
  left: -17px;
  top: 50%;
  transform: translateY(-50%);
}
.showcasing_slider button.owl-next{
  position: absolute;
  right: -17px;
  top: 50%;
  transform: translateY(-50%);
}
.showcasing_slider button.owl-prev i {
  width: 30px;
  height: 30px;
  background: #000;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 23px;
  justify-content: center;
  border-radius: 50px;
}

.showcasing_slider button.owl-next i {
  width: 30px;
  height: 30px;
  background: #000;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 23px;
  justify-content: center;
  border-radius: 50px;
}
.owl-carousel .owl-stage {
  display: flex;
   }
   .owl-carousel .owl-item {
  display: flex;
   flex: 1 0 auto;
   }
   .showcasing_content  .heading {
    height: 100%;
    width: 100%;
    padding-right: 35px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
   
}
/* tools_sec */
.marquee {
  overflow: hidden;
  position: relative;
  background: #fefefe;
}

.marquee p {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 50px;
  text-align: center;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -moz-animation: scroll-left 2s linear infinite;
  -webkit-animation: scroll-left 2s linear infinite;
  animation: scroll-left 20s linear infinite;
}

@-moz-keyframes scroll-left {
  0% {
      -moz-transform: translateX(100%);
  }
  100% {
      -moz-transform: translateX(-100%);
  }
}

@-webkit-keyframes scroll-left {
  0% {
      -webkit-transform: translateX(100%);
  }
  100% {
      -webkit-transform: translateX(-100%);
  }
}

@keyframes scroll-left {
  0% {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
  }
  100% {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
  }
}

/* testimonials_sec */
.testimonials_sec{
  background-color: #11589a;
  color: #fff;
  
}
.testimonials_sec .testimonials_content .contented {
  border: 3px solid rgba(0, 0, 0, 0.26);
  padding: 30px;
  position: relative;
  z-index: 1;
  background-color: #00000054;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.testimonials_sec .testimonials_content .contented .coma_sty {
  margin-bottom: 30px;
  position: relative;
  margin-top: -66px;
  width: 67px;
}
.testimonials_sec .testimonials_content{
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.testimonials_sec .testimonials_content .top_content{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.testimonials_sec .testimonials_content .content_profile {
  position: relative;
  z-index: 1;
  margin-top: -95px;
 
}
.review_slider .item{
  margin: 70px 0 0;

}

.testimonials_sec .testimonials_content .content_profile img{
  min-width: 60px;
  min-height: 60px;
  border-radius: 50px;
  overflow: hidden;
}

/* want_work_sec */
.want_work_sec{
  background: url('../../../public/assets/image/want-work-bg.jpeg') no-repeat center;
  background-size: cover;
  padding: 120px 0;
}

/* adret_sec */
.adret_sec .top_img{
width: 85px;
height: 85px;
background-color:initial;
border: 1px solid #11589a;
border-radius: 5px;
display: flex;
align-items: center;
padding: 15px;
justify-content: center;
margin-bottom: 15px !important;
}

.review_slider button.owl-prev{
  position: absolute;
  left: -45px;
  top: 58%;
  transform: translateY(-50%);
}
.review_slider button.owl-next{
  position: absolute;
  right:  -45px;
  top: 58%;
  transform: translateY(-50%);
}
.review_slider button.owl-prev i {
  width: 30px;
  height: 30px;
  background: #000;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 23px;
  justify-content: center;
  border-radius: 50px;
}
.review_slider button.owl-next i {
  width: 30px;
  height: 30px;
  background: #000;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 23px;
  justify-content: center;
  border-radius: 50px;
}
/* home_contact */
.home_contact{
  line-height: 0;
}
.home_contact iframe{
  width: 100%;
  height: 600px;
}
.home_contact .contact-form{
  background-color: #0b3b67;
  padding: 30px;
  text-align: center;
  height: 100%;
}
.home_contact .contact-form h4{
  color: #fff;
  margin-bottom: 25px;
}
.home_contact .contact-form input {
  height: 50px;
  border-radius: 5px;
  color: #ffffff;
  opacity: 1;
  width: 100%;
  background-color: initial;
  border: 1px solid #ced3d2;
padding: 12px;
}
.home_contact .contact-form input::placeholder{
  color: #fff;
  opacity: 1;
}
.home_contact .contact-form select {
  height: 50px;
  border-radius: 5px;
  color: #ffffff;
  width: 100%;
  opacity: 1;
  background-color: initial;
  border: 1px solid #ced3d2;
  box-shadow: none;
  outline: none;
}
.home_contact .contact-form option{
  color: #000000;
}
.home_contact .contact-form textarea {
  border-radius: 5px;
  color: #ffffff;
  border: 1px solid #ced3d2;
  background-color: initial;
  width: 100%;
  opacity: 1;
  padding: 12px;
  min-height:150px;
}
.home_contact .contact-form button {
  background: #11589a;
  border: 1px solid #11589a;
  padding: 10px 50px;
  outline: none;
  cursor: pointer;
  display: inline-block;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  border-radius: 5px;
  height: 60px;
  width: 100%;
  letter-spacing: 2px;
  transition: all .5s ease-in-out;
}
.home_contact .contact-form textarea::placeholder{
  color: #fff;
  opacity: 1;
}
.home_contact{
  position: relative;
}
.home_contact .container .contact-form{
  position: absolute;
  right: 0;
  top: 0;
  width: 500px;
  margin-right: 100px;
}

/* footer */
footer{
  padding: 70px 0 50px;
  background-color: #000000;
  color: #8f8f8f;
}
footer .ft_content h4{
  color: #fff;
  font-size: 23px;
  margin-bottom: 25px;
}
footer .ft_logo img {
  width: 180px;
  margin-bottom: 30px;
}
footer .ft_gap_left{
  margin-left: -15px;
}
footer ul.list-unstyled.ft_links li {
  margin-bottom: 8px;
}
footer .ft_links2 li{
  margin-bottom: 8px;
  display: flex;
  align-items: self-start;
  color: #8f8f8f;
}
footer .ft_links2 li i{
  font-size: 27px;
  margin-right: 10px;
  margin-top: 5px;
  color: #12599c;
}
footer .ft_links2 li i.fa-globe{
  font-size: 35px;
  margin-right: 10px;
  margin-top: 5px;
  color: #12599c;
}
footer .ft_links2 li a{
  color: #8f8f8f;
} 
footer .ft_links li a{
  color: #8f8f8f;
} 
footer .ft_links2 li a .ft_call img{
  width: 30px;
  margin-right: 5px;
}
footer .ft_border{
  width: 100%;
  height: 1px;
  margin: 35px 0;
  background-color: #2b2b2b;
}
footer .ft_socail{
 display: flex;
 justify-content: right;
 align-items: center;
 gap: 10px;
}
footer .ft_socail a{
  width: 45px;
  height: 45px;
  background-color: #11589a;
  border-radius: 50px;
  color: #fff;
  display:flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
}

.home_contact .contact-form {
  background-color: #0b3b67;
  padding: 30px;
  text-align: center;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 95px;
  width: 500px;
}

.mobile-fixed-button{
  display: none;
}

/* .inner page .. */

.inner-page h1 {
  color: #11589a;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.inner-page {
  padding: 200px 0 100px
}
.inner_content {
  background-color: rgb(255 255 255 / 48%);
  border-radius: 5px;
  padding: 15px;
  text-align: center;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 10px;
  margin-bottom: 16px;
  list-style: none;
  background-color: initial !important;
  border-radius: 4px;
  justify-content: center;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 10px;
  margin-bottom: 16px;
  list-style: none;
  background-color: initial !important;
  border-radius: 4px;
}
.breadcrumb li a{
  color: #11589a;
}
.breadcrumb-item.active {
  color: #000000 !important;
}

#contact-bg{
  background: url('../../../public/assets/image/contact-banner.jpg') no-repeat center;
  background-size: cover;
}
#clients-bg{
  background: linear-gradient(rgba(0,0,0,0.3) ,rgba(0,0,0,0.3)) , url('../../../public/assets/image/clients-banner.jpg') no-repeat center;
  background-size: cover;
}
#career-bg{
  background: linear-gradient(rgba(0,0,0,0.3) ,rgba(0,0,0,0.3)) , url('../../../public/assets/image/career-banner.jpg') no-repeat center;
  background-size: cover;
}

/* contact Us */
.contact-info-top {
  margin-top: 25px
}

.contact .p-contact {
  margin: initial !important
}

.contact .contact-info form {
  margin-top: 15px
}

.contact .contact-top {
  background: #0060b1;
  padding: 33px 20px;
  width: 100%;
  height: 100%;
  border-radius: 10px
}

.contact .contact-icon {
  position: relative
}

.contact .contact-info-top a {
  color: #fff
}

.contact .contact-info-top p {
  color: #fff
}

.contact .contact-icon i {
  background: #041450;
  width: 70px;
  height: 70px;
  margin: 0 auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 28px
}
.contact  .contact-top{
  text-align: center;
}

.contact .contact-left-el {
  margin-bottom: 23px;
  display: flex;
  align-items: center;
  background: #f7f7f7
}
.contact-info-top h4 {
  color: #fff;
}
.contact .contact-left-el1 {
  margin-bottom: 26px;
  margin-top: 30px;
  display: flex;
  align-items: flex-start
}

.contact .contact-left-el,.contact-left-el1 a {
  color: #777;
  transition: all .3s ease-in-out
}
.contact .contact-icon img {
  width: 60px;
  border-radius: 5px;
}

.contact .contact-form {
  box-shadow: 0 10px 25px #00000024;
  padding: 35px 25px;
  border-radius: 10px
}

.contact .contact-left {
  background: #fff;
  padding: 35px 25px;
  box-shadow: 0px 10px 40px #ebebeb;
  width: 100%;
  height: 100%
}

.contact .contact-left-el,.contact-left-el1 a:hover {
  color: #5ad2d2
}

.contact .contact-form input {
  font-family: 'Poppins',sans-serif;
  height: 60px;
  border-radius: 5px;
  color: #666;
  border: 1px solid #e2e2e2;
  padding: 12px;
}

.contact .contact-form select {
  font-family: 'Poppins',sans-serif;
  height: 60px;
  border-radius: 5px;
  color: #666;
  border: 1px solid #e2e2e2;
  padding: 12px;
  box-shadow: none;
  outline: none;
  background-color: initial;
}
.contact .contact-form textarea {
  font-family: 'Poppins',sans-serif;
  border-radius: 5px;
  color: #666;
  border: 1px solid #e2e2e2;
  width: 100%;
  padding: 12px;
  background-color: initial;
}

.contact .contact-form button {
  background: #11589a;
  border: 1px solid #11589a;
  outline: none;
  cursor: pointer;
  display: inline-block;
  color: #fff;
  font-family: 'Poppins',sans-serif;
  text-transform: uppercase;
  border-radius: 50px;
  width: 280px;
  height: 60px;
  letter-spacing: 2px;
  transition: all .5s ease-in-out
}

.contact .contact-form button:hover {
  background: #000;
  border: 1px solid #000;
  color: #fff
}

.contact .contact-form .form-check .form-check-input {
  height: 18px;
  width: 18px;
  margin-right: 20px
}

.contact .contact-form .form-check {
  display: flex;
  align-items: center
}

.contact .contact-form h5 {
  color: #fff
}

.contact .contact-form .form-check label {
  color: #fff;
  margin-bottom: 0;
  padding-bottom: 0
}

.contact .contact-form .heading h3 {
  text-align: center;
  margin-bottom: 25px;
  color: #248a3a
}

.map {
  line-height: 0
}
.protfilio_sec .meet_team_con {
  overflow: hidden;
  border-radius: 10px;
}
.protfilio_sec .meet_team_con img {
  transition: all 0.6s;
  border-radius: 10px;
}
.protfilio_sec .meet_team_con:hover img{

  transform: scale(1.1);
}
.row_gaping{
  margin-bottom: 15px;
}

/* hppy_clien */
.hppy_clien{
  width: 100%;
  height: 100%;
  text-align: center;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 25px;
  background-color: #fff;
  color: #000000;
}
.hppy_clien .rating i {
  font-size: 20px;
  color: #fbbc05 !important;
}
.hppy_clien .rating li{
  font-size: 17px;
  color: #212121;
  font-weight: 700;
}
.hppy_clien .rating li span{
  color: #000000;
  font-weight: 700;
}
.hppy_clien ul{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

/* faq */

/* ........faq..... */

.accordion__body {
  display: none;  
  padding: 23px 20px;
}

.accordion__body.show{
display: block;
transition: all 0.5s ease;
}

.accordion__body ul li {
  font-size: 16px;
  margin-bottom: 7px;
  position: relative;
  padding-left: 20px;
}
.accordion__body ul li::before{
  content: "";
  background:#110000;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  left: 0;
  top:10px;
  position: absolute;
}
.accordion__body ul {
  margin-top: 25px;
  transition: all 0.6s ease;
}

.accordion__title {
  cursor: pointer;
  margin-bottom: 0px;
  position: relative;
  color: rgb(255 255 255);
  font-weight: 400;
  padding: 15px 32px 15px 22px;
  font-size: 26px !important;
  margin-bottom: 0 !important;
  background-color: #11589a;
}

.accordion__title:after {
  content: "";
  width: 31px;
  height: 42px;
  position: absolute;
  right: 0;
  top: 20px;
  background: url(../../../public/assets/image/faq-icon1.png) no-repeat;
  background-size: 17px;
}
.accordion__title.active {
  color: #ffffff;

  
}
.accordion__title.active:after {
  /* top: -3px; */
  background: url(../../../public/assets/image/faq-icon.png) no-repeat;
  background-size: 17px;
  /* transform: rotate(-180deg); */
  top: 29px;
}
.accordion__item {
  /* padding: 16px 0px; */
  border: 1px solid rgb(17 88 154);
  margin-bottom: 20px;
}

.faq_list {
  margin-bottom: 50px;
}
.accordion__item h3 {
  font-size: 20px;
  color: #3c3c3c;
  font-weight: 700;
  margin-top: 30px;
}
.accordion__body h5 {
  font-size: 22px;
  font-weight: 500;
  color: #0e0e0e;
}
.accordion__body h5 a{
  color: #024fff;
}

/* portfolio_sec */
.portfolio_sec{
  background: rgb(87,183,202);
  background: linear-gradient(176deg, rgba(87,183,202,0.7370689655172413) 19%, rgba(249,249,249,0.11422413793103448) 84%);
}
.portfolio_sec .contact-form{
  background-color: #0b3b67;
  padding: 30px;
  text-align: center;
  height: 100%;
  border-radius: 10px;
}
.portfolio_sec .contact-form h4{
  color: #fff;
  margin-bottom: 25px;
}
.portfolio_sec .contact-form input {
  height: 50px;
  border-radius: 5px;
  color: #ffffff;
  width: 100%;
  background-color: initial;
  border: 1px solid #ced3d2;
  opacity: 0.7;
padding: 12px;
}
.portfolio_sec .contact-form input::placeholder{
  color: #fff;
  opacity: 1;
}
.portfolio_sec .contact-form select {
  height: 50px;
  border-radius: 5px;
  color: #ffffff;
  width: 100%;
  opacity: 0.7;
  background-color: initial;
  border: 1px solid #ced3d2;
  box-shadow: none;
  outline: none;
}
.portfolio_sec .contact-form option{
  color: #000000;
}
.portfolio_sec .contact-form textarea {
  border-radius: 5px;
  color: #ffffff;
  border: 1px solid #ced3d2;
  background-color: initial;
  width: 100%;
  opacity: 0.7;
  padding: 12px;
}
.portfolio_sec .contact-form button {
  background: #11589a;
  border: 1px solid #11589a;
  padding: 10px 50px;
  outline: none;
  cursor: pointer;
  display: inline-block;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  border-radius: 5px;
  height: 60px;
  width: 100%;
  letter-spacing: 2px;
  transition: all .5s ease-in-out;
}
.portfolio_sec .contact-form textarea::placeholder{
  color: #fff;
  opacity: 1;
}

.portfolio_sec1 .nav-pills .nav-link.active, .portfolio_sec1  .nav-pills .show > .nav-link {
  color: #FFF;
  background-color: #11589a;
  padding: 10px 0px;

}
.portfolio_sec .nav-pills .nav-link {
  border-radius: 0px;
}
.nav-pills .nav-link {
  border-radius: 0px;
  width: 180px;
  padding: 10px 10px;
  border: 1px solid #11589a;
  font-weight: 500;
  font-size: 16px;
}
.portfolio_sec1 .heading h3 {
  font-weight: 400;
  font-size: 27px;
  margin-top: 19px;
}
.content_portfolio{
  width: 100%;
  height: 300px;
  position: relative;
  padding: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.content_portfolio .overlay_dec{
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.808);
  color: #fff;
  padding: 25px;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.6s;
}
.content_portfolio .overlay_dec h5{
  font-size: 20px;
  font-weight: 300;
}
.content_portfolio .overlay_dec ul li{
font-weight: 300;
display: flex;
align-items: start;
margin-bottom: 10px;
}
.content_portfolio .overlay_dec ul li i{
  margin-right: 8px;
}
.content_portfolio:hover .overlay_dec{
  opacity: 1;
  top:0;
}
.bg_color1{
  background-color: #f7f7f7;
}
/* .....logo_design_business */
.logo_design_business{
  background: url('../../../public/assets/image/logo-design-bg1.jpg') no-repeat center;
  background-size: cover;
  color: #fff;
}
.logo_design_best iframe{
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  height: 450px;
}
.logo_design_project .heading {
  background-color: #11589a;
  padding:30px;
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: 11;
}
.logo_design_project .heading::before {
  content: "";
  position: absolute;
  background: rgb(17, 88, 154);
  left: -30%;
  top: 0px;
  clip-path: polygon(31% 0, 100% 0, 100% 100%, 77% 100%);
  z-index: -1;
  width: 250px;
  height: 100%;
}
.logo_design_project .heading h4 a{
  color: #fff;
}
.logo_design_project .heading h4 {
  font-weight: 400;
}
.logo_design_project .heading .btn_sec{
  margin-top: 45px;
}
.logo_design_project .content_img{
  width: 100%;
  height: 100%;
  position: relative;
  z-index: -1;
}
.logo_design_sec .content_why {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  margin: 17px 0 0px;
}
.logo_design_sec .content_why .left_icon {
  min-width: 75px;
  min-height: 75px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0a3f6f;
  transition: all 0.6s;
  padding: 8px;
}
.logo_design_sec .content_why h5{
  margin-bottom: 10px;
  color: #0a3f6f;

}
.logo_design_sec .content_why  p{
  line-height: 25px;
}
.logo_con_port {
  background-color: #fff;
  padding: 10px;
  border: 1px solid #e3e3e3;
}
#faq_sec .accordion .accordion__title {
  font-size: 18px !important;
}
.android_app_expertise{
  padding: 35px 22px;
  color: #fff;
  border-radius: 10px;
  overflow: hidden;
}
.android_app_bg1{
  background: url('../../../public/assets/image/hire-android-app-developer-portfolio-bg1.jpg') no-repeat center;
  background-size: cover;
}
.android_app_expertise .content_img{
  text-align: center;
  position: relative;
  margin-bottom: -100px;
  margin-left: 100px;

}
.android_app_expertise .content_img img {
  width: 290px;
}
.success_sec .success_con{
  display: flex;
  align-items: center;
  gap: 20px;
}
.success_sec .success_con .success_img{
  min-width: 75px;
  min-height: 75px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0a3f6f;
  transition: all 0.6s;
  padding: 8px;
}
.success_sec .success_con span {
  font-size: 28px;
  font-weight: 500;
  line-height: 23px;
}
.success_sec{
  background: url('../../../public/assets/image/android-app-development-bg1.jpg') no-repeat center;
  background-size: cover;
  color: #fff;
  padding: 50px 0;
}

.porcese_ber {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.porcess_con .porcess_icon {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0a3f6f;
  transition: all 0.6s;
  margin: 0 auto;
  position: relative;
  background: #11589a;
  z-index: 11;
}
.porcess_con .porcess_icon::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 194px;
  height: 1px;
  background-color: #11589a;
}
.porcess_con:last-child .porcess_icon::before{
  content: "";
  position: initial !important;
  left: 0;
  top:50%;
  width: initial !important;
  height: 1px;
  background-color: #024fff;
  display: none !important;
}
.porcese_ber .porcess_con .porcess_icon img {
  width: 36px;
  position: relative;
z-index: 111;
}
.porcese_ber .porcess_con  h5{
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.porcese_ber .porcess_con {
  width: 16%;
  text-align: center;
}
#android_app  .heading .btn_sec {
  margin-top: 0px;
}

#website-design-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../../../public/assets/image/website-banner.svg') no-repeat center;
  background-size: cover;
}

#nodejs-design-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('https://img.freepik.com/free-photo/programming-background-with-person-working-with-codes-computer_23-2150010125.jpg?w=900&t=st=1699947800~exp=1699948400~hmac=3e0df2b00e1a711c95747dd4c70fd364a1f038e02c3aed2d6f076df63aa5aaf9') no-repeat center;
  background-size: cover;
}
/* wed design */

.focals p {
  margin-bottom: 0;
}

.center-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  text-align: left;
}

.border-end {
  border-right: 1px solid lightgray;
}

.icon-color {
  color: #11589a;
  font-size: 50px;
  padding: 0;
  margin: 0;
}

.icon-color-white {
  color: white;
  font-size: 50px;
  padding: 0;
  margin: 0;
}



.icon-style {
  border: 2px solid #0d6efd;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  text-align: center;
}

.icon-style-white {
  border: 2px solid white;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  text-align: center;
}


.details-website .subtitle {
  margin: 0;
  margin-left: 10px;
  font-size: 24px;
  color: #0d6efd;
}

.section-subtitle {
  margin-top: 30px;
  font-weight: 500;
}

/* =======================navtabs===================== */

.nav-tabs {
  border: 0;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-bottom: 4px solid #0d6efd;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link {
  background: #ebebeb;
  border-radius: 0;
  padding: 30px 0;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  color: #0d6efd;
}

.tab-content .tab-pane {
  background: rgb(235, 235, 235);
  padding: 20px;
}

.accordion-button {
  background: #0d6efd;
  width: 100%;
  text-align: left;
  padding: 10px;
  color: white;
  border-radius: 5px;
  position: relative;
}

.accordion-header {
  margin: 0;
}

.accordion-item {
  margin: 20px 0;
}

.accordion-button::after {
  content: '+';
  position: absolute;
  right: 10px;
  font-size: 30px;
  top: 50%;
  translate: 0 -50%;

}

.accordion-body {
  padding-top: 0;
  margin-top: 20px;
  padding: 20px;
  border-radius: 5px;
}

.details-heading {
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
  padding-bottom: 0;
}

.center-row img {
  margin: 0 auto;
}

.center-row p {
  text-align: center;
}

.small-hire {
  position: relative;
}

.small-hire img {
  height: 200px;
}



.small-hire .small-hire-content {
  position: absolute;
  top: 50%;
  right: 10px;
  translate: 0 -50%;
}

.choose-us {
  padding: 50px 30px;
  border: none;
  color: black;
  margin-top: 70px;
  height: 300px;
}

.choose-us:hover {
  box-shadow: 0 5px 20px 0 rgba(50, 50, 50, 0.5)
}

.choose-us img {
  margin-top: -100px;
}



@media screen and (width <=575px) {



  .small-hire .small-hire-content>p,
  h3 {
    font-size: 16px;
  }

  .small-hire img {
    height: 200px;

  }

  .small-hire .small-hire-content {
    position: absolute;
    top: 50%;
    right: 50%;
    translate: 50% -50%;
    width: 100%;
    padding: 10px;
    text-align: center;
  }
}


.bg-dotted {
  position: relative;
}

.bg-dotted::after {
  width: 300px;
  height: 120px;
  content: "";
  background: url(https://www.webguru-india.com/assets/images/elearning-management-system-dot-bg.png) repeat center;
  position: absolute;
  top: -50px;
  right: -50px;
  z-index: -1;
}

.mobile-friendly-image::after {
  background: #4afbcf;
  content: "";
  top: -100px;
  bottom: -100px;
  -moz-transform: skew(-27deg,0deg);
  -o-transform: skew(-27deg,0deg);
  -webkit-transform: skew(-27deg,0deg);
  -ms-transform: skew(-27deg,0deg);
  transform: skew(-27deg,0deg);
  width: 300px;
  position: absolute;
  left: 10px;
  outline: 3px solid #7975f8;
  outline-offset: -15px;
  z-index: -1;
}

.solutions-image {
  margin-bottom: 30px;
}
.website-banner .heading h3{
  color: #000;
}
.tab-content .tab-pane {
  background: rgb(235, 235, 235);
  padding: 20px;
  color: #000;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-link {
  background: #ebebeb;
  border-radius: 0;
  padding: 20px 0;
}
.mr-5 {
  margin-right: 12px !important;
}
.mb-3 {
  margin-bottom: 10px !important;
}
.icon-style {
  border: 2px solid #0d6efd;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  text-align: center;
  margin-bottom: 7px;
}

/* 20.11.23 */


.hire_content {
	text-align: center;
	background-color: #fff;
	box-shadow: 0 2px 25px #ddd;
	padding: 15px;
	border-radius: 5px;
	width: 100%;
	height: 100%;
}
.hire_content img{
  margin-bottom: 10px;
}
.hire_content h5 {
	font-size: 16px;
	margin-bottom: 0;
	margin-top: 8px;
}
.hire_content_anguler{
  background: url('../../../public/assets/image/hire-angular-bg1.jpg') no-repeat center;
  background-size: cover;
  padding: 0;
}
.hire_content_anguler .small_hire_content{
  background-color: #0060b1;
  padding: 30px;
  color: #fff;
}
.hire_content_anguler .small_hire_content a{
  color: #fff;
}
.hire_content_anguler .small_hire_content h3{
  color: #fff;
}
.hire_content_anguler .small_hire_content .btn_sec{
  margin-top: 25px;
}


.choose-us {
	padding: 30px 15px;
	border: none;
	color: black;
	margin-top: 70px;
	min-height: 256px;
	/* height: 100%; */
	background-color: #fff;
	border-radius: 5px;
}
.choose-us .choose-us-img {
	width: 90px;
	height: 90px;
	background-color: #0b345b;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	margin-top: -66px;
}
.choose-us .choose-us-img img {
	margin-top: 0px;

}
.about_sec2 .content_about p{
  margin-bottom: 0;
  line-height: 22px;
}
#about-bg{
  background: url('../../../public/assets/image/about-banner.png') no-repeat center;
  background-size: cover;
}
.service_sec1{
  position: relative;
}
.service_sec1  video{
  width: 100%;
  height: 460px;
  object-fit: cover;
}
.service_sec1 .container{
  position: absolute;
  left: 5%;
  top: 50%;
  transform: translateY(-50%);
}
.service_sec1 .heading{
  color: #fff;
}
.service_sec1 .gaps{
  padding: 25px;
}
#service_sec .service_ul li {
  display: inline-block;
  width: 280px;
}

/* seo_service_bg */
#seo_service_bg{
  background: url('../../../public/assets/image/seo-services-banner.jpg') no-repeat center;
  background-size: cover;
}
#social_marketing_service_bg{
  background: url('../../../public/assets/image/social-media-marketing-banner.jpg') no-repeat center;
  background-size: cover;
}
#ppc-management-bg{
  background: url('../../../public/assets/image/ppc-management-banner.jpg') no-repeat center;
  background-size: cover;
}
#local_seo_service_bg{
  background: url('../../../public/assets/image/local-seo-banner.jpg') no-repeat center;
  background-size: cover;
}
#content_marketing_service_bg{
  background: url('../../../public/assets/image/content-marketing-services-banner.jpg') no-repeat center;
  background-size: cover;
}

.client-image-fit {
  width: 300px;
  height: 75px;
  object-fit: contain;
}

.error{
  color: #ff6600;
  font-size: 13px;
}
.error i{
  margin-right: 6px;
}

/* For Header */

@media screen and (max-width:1200px){
  .contact-us-icon{
    font-size: 13px !important;
  }
}
@media screen and (max-width:1040px){
  .contact-us-icon{
    font-size: 11px !important;
  }
}

/* For .portfolio_sec */
.portfolio-contact{
  text-align: left;
}
.portfolio-contact-error{
  color: #ffd700;
  font-size: 14px;
}

.message-input{
  height: 100px !important;
}

.message-input::placeholder{
  margin-top: -50%;
}

/* 08/12/2023 */

footer .ft_links2 li a .ft_call img {
	margin-right: 5px;
	width: 33px;
	height: 23px;
	border: 1px solid #b0b0b0c9;
}
.company_rating > div > p {
	font-size: 35px !important;
	margin: 8px 0px 8px 0px !important;
}

/* ...pricing_sec... */
.pricing_content {
  transition: all 0.6s;
}
.pricing_content .pricing_img img{
  border-radius: 5px;
  transition: all 0.6s;
}
.pricing_content .pricing_img{
  border-radius: 5px;
  transition: all 0.6s;
  overflow: hidden;
}
.pricing_content  .btom{
  text-align: center;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 90%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: -20px;
  border-radius: 5px;
  transition: all 0.6s;
}
.pricing_content  .btom h4{
  color: #000;
  font-size: 20px;
  font-weight:500;
  margin-bottom: 0;
}
.pricing_content .pricing_img:hover img{
  transform: scale(1.1);
}
.pricing_content:hover .btom{
  background-color: #11589a;
}
.pricing_content:hover .btom h4{
  color: #fff;
}
#v-pills-tab{
  text-align: center;
}
#v-pills-tab ul{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
#v-pills-tab a {
  padding: 13px 10px;
  display: inline-block;
  background: #fff;
  margin: 3px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: #000000f0;
  min-width: 144px;
  height: 60px;
  font-weight: 500;
  border-radius: 10px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
#v-pills-tabContent .tab-pane{
  background-color: initial;
}

#nav-tab {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4px;
}

#v-pills-tab .nav-link {
  transition: all 0.6s;
}

#v-pills-tab .nav-link.active {
  color: #fff;
  background-color: #11589a;
  color: #fff !important;
}

#v-pills-tab .nav-link:hover {
  background-color: #11589a;
  color: #fff !important;
}

.product .pro-title a {
  color: #252525;
  font-family: var(--archivo);
}
/* 
.....pricing_card... */
.pricing_card{
  background-color: #eeeeee;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.pricing_card .top_header{
  color: #fff;
  background-color: #11589a;
  padding: 35px 30px;
  text-align: center;
  position: relative;
}
.pricing_card .top_header h4{
  font-size: 30px;
  font-weight: 500;
  text-transform:uppercase;
  margin-bottom: 12px;
}
.pricing_card .border_btn {
	width: 100%;
	height: 1px;
	background-color: #ffffff7a;
}
.pricing_card .pirze_sec {
	font-size: 24px;
  display: flex;
  align-items: center;
justify-content: center;
}
.pricing_card .pirze_sec span{
  font-size: 50px;
}
.pricing_card .down_arow{
  position: relative;
}
.pricing_card .down_arow .down_arow_cont{
  width: 60px;
  height: 60px;
  background-color: #11589a;
  color: #fff;
  border: 3px solid #fff;
  position: absolute;
  top: 50%;
  left: 50%;
transform: translate(-50% ,-50%);
}
.pricing_card .down_arow i {
	position: relative;
	z-index: 11;
	color: #ffffff;
	font-size: 22px;
	transform: rotate(315deg);
	font-weight: 500;
}
.pricing_card .down_arow {
	position: relative;
	margin-top: 30px;
	transform: rotate(45deg);
	margin-bottom: -48px;
}
.pricing_card  .pricing_body{
  background-color: #fcfcfc;
  padding: 35px 30px;
  text-align: center;
  width: 100%;
  height: 100%;
}
.pricing_card  .pricing_list li{
  margin-bottom: 10px;
  color: #3c3b3b;
}
.pricing_card .pricing_list li i {
	font-size: 15px;
	color: #024fff;
	margin-right: 5px;
}
.pricing_card .con_month p{
  font-size: 20px;
  padding-bottom: 15px;
}
.pricing_card  .pricing_list{
  margin: 25px 0;
}
.pricing_card .over_heading {
	background-color: #0b3b67;
	position: absolute;
	left: -44px;
	top: 20px;
	width: 200px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: rotate(-40deg);
}
.pricing_card .over_heading span{
  font-size: 20px;
  color: #ffffff;
  padding: 10px;
}
.btn_buy{
  background: #11589a;
  border: 1px solid #11589a;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  padding: 11px 0;
  text-align: center;
  text-transform: capitalize;
  transition: all .5s ease-in-out;
  width: 250px;
}
.btn_buy:hover {
	background: initial;
	border: 1px solid #11589a;
	color: #11589a;
}
#pricing-bg{
  background:linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)), url('../../../public/assets/image/Packages/pricing-banner.jpg') no-repeat center;
  background-size: cover;
}

#design-and-branding-bg{
  background: url('../../../public/assets/image/Packages/design-and-branding-banner.jpg') no-repeat center;
  background-size: cover;
}

#v-pills-tab a.nav-link{
  width: 160px;
  font-size: 15px;
}
.switch_butt .form-switch {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 200px;
	margin-bottom: 15px;
}

.switch_butt .form-switch .form-check-input {
	width: 75px;
	margin-left: inherit;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
	background-position: left center;
	border-radius: 32px;
	height: 35px;
}
.form-switch .form-check-input:checked{
  width: 75px;
	margin-left: inherit;
  border-radius: 32px;
	height: 35px;
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e");
}
.switch_butt label {
	font-size: 27px;
	margin-top: -10px;
}
.switch_butt .form-switch {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 200px;
	margin-bottom: 15px;
	margin: 0 38px 0 auto;
}
.switch_butt .form-switch .form-check-input {
	margin-right: 20px;
}
.showPricingMenu{
  display: block;
}
.hidePricingMenu{
  display: none;
}

/* for top call icon */
.top_ber .topber_left ul li a img {
	width: 30px;
	height: 30px;
	border-radius: 50px;
}
.top_ber .topber_left ul li a img {
	width: 29px;
	height: 29px;
	border-radius: 50px;
}
.m-backtotop.active {
  bottom: 10px;
  opacity: 1;
  }